import React, {useEffect} from 'react';
import {Carousel, Row, Col, Button} from 'react-bootstrap';
import {useStaticQuery, graphql, Link} from 'gatsby';
import Img from 'gatsby-image';

const NewsCarousel = () => {
  const images = useStaticQuery(graphql`
    query {
      genomepaintImage: file(relativePath: {eq: "genome-paint-announcement@2x.jpg"}) {
        ...newsImage
      }
      sjcImage: file(relativePath: {eq: "albeituni-nichols.jpg"}) {
        ...newsImage
      }
      sjcpaperImage: file(relativePath: {eq: "SJC-Paper@2x.jpg"}) {
        ...newsImage
      }
    }
    fragment newsImage on File {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
  `);

  const normalizeHeights = () => {
    const items = document.querySelectorAll('.news-carousel .carousel-item');
    const heights = [];

    /* eslint-disable no-param-reassign */
    items.forEach(item => {
      const child = item.children[0]; // This is the nested `Row`

      // Force show all items, and unset any imposed height on the child element
      item.style.display = 'block';
      child.style.minHeight = '0px';

      // Find the true height of each item, then clear force showing
      const {height} = child.getBoundingClientRect();
      heights.push(height);
      item.style.display = '';
    });

    // Find the largest height, then force that as the minimum for consistency
    const minHeight = Math.max.apply(null, heights);
    items.forEach(item => {
      const child = item.children[0];
      child.style.minHeight = `${minHeight}px`;
    });
    /* eslint-enable no-param-reassign */
  };

  useEffect(() => {
    normalizeHeights();
    window.addEventListener('resize', normalizeHeights);
    window.addEventListener('orientationchange', normalizeHeights);
    return () => {
      window.removeEventListener('resize', normalizeHeights);
      window.removeEventListener('orientationchange', normalizeHeights);
    };
  }, []);

  return (
    <Carousel className="news-carousel">
      <Carousel.Item>
        <Row>
          <Col lg={4} className="d-flex">
            <Img
              className="news-card-img"
              fixed={images.sjcpaperImage.childImageSharp.fixed}
              alt="St. Jude Cloud"
            />
          </Col>
          <Col lg={8} className="d-flex flex-column">
            <h3>St. Jude Cloud Paper</h3>
            <h5 className="text-muted">
              Genomic Data-Sharing Ecosystem for the Precision Medicine Era
            </h5>
            <p>
              St. Jude Children’s Research Hospital created the largest cloud-based genomic resource
              for pediatric cancer and a data-sharing model to accelerate life-saving research.
            </p>

            <Button
              as={Link}
              className="mt-auto me-auto"
              target="_blank"
              to="/announcements/st-jude-cloud-paper?sc_icid=home-feature"
            >
              View Announcement
            </Button>
          </Col>
        </Row>
      </Carousel.Item>
      <Carousel.Item>
        <Row>
          <Col lg={4} className="d-flex">
            <Img
              className="news-card-img"
              fixed={images.genomepaintImage.childImageSharp.fixed}
              alt="PeCan"
            />
          </Col>
          <Col lg={8} className="d-flex flex-column">
            <h3>GenomePaint paints the cancer genomes on St. Jude Cloud</h3>

            <p>
              GenomePaint helps researchers visualize and explore genetic data from thousands of
              childhood cancer datasets available on St. Jude Cloud. Researchers around the world
              can also use GenomePaint to analyze their own data at no cost.
            </p>

            <Button className="mt-auto me-auto" href="https://genomepaint.stjude.cloud/">
              Access GenomePaint
            </Button>
          </Col>
        </Row>
      </Carousel.Item>
      <Carousel.Item>
        <Row>
          <Col lg={4} className="d-flex">
            <Img
              className="news-card-img"
              fixed={images.sjcImage.childImageSharp.fixed}
              alt="St. Jude Cloud"
            />
          </Col>
          <Col lg={8} className="d-flex flex-column">
            <h3>St. Jude Cloud is first to release real-time clinical genomics data</h3>

            <p>
              St. Jude Cloud is speeding up scientific discoveries through access to real-time
              patient genomic clinical data. This ground-breaking initiative brings frequent release
              of high-quality genomic data direct from the clinic to the cloud.
            </p>

            <Button
              className="mt-auto me-auto"
              href="https://www.stjude.org/media-resources/news-releases/2019-medicine-science-news/st-jude-cloud-to-provide-access-to-real-time-clinical-genome-sequencing-data.html"
            >
              Read the News Release
            </Button>
          </Col>
        </Row>
      </Carousel.Item>
    </Carousel>
  );
};

export default NewsCarousel;
