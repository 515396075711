import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';

import PropTypes from 'prop-types';
import CardApp from './card-app';

const CardAppPeCan = props => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "icon_pecan.png"}) {
        id
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

  return <CardApp icon={data.file.childImageSharp.fixed} {...props} />;
};

CardAppPeCan.propTypes = {
  icon: PropTypes.object.isRequired,
  tag: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  url: PropTypes.string,
  lg: PropTypes.number,
  sm: PropTypes.number,
};

CardAppPeCan.defaultProps = {
  tag: 'Pediatric Cancer Knowledge Base',
  title: 'PeCan',
  description:
    'Browse the pediatric cancer variant knowledge base curated by researchers at St. Jude including pathogenicity classification and variant recurrence.',
  buttonText: 'Explore PeCan',
  url: 'https://pecan.stjude.cloud',
};

export default CardAppPeCan;
