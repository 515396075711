import React from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Col} from 'react-bootstrap';
import {Link} from 'gatsby';

const CardDomain = ({domainTitle, description, color, url}) => (
  <Col md={12} lg={3} as={Link} to={url} className="card-domain__column">
    <Card className="card-domain">
      <h4 className={color}>{domainTitle}</h4>
      <p className="card-domain__description">{description}</p>
      <Button variant={color}>Learn More</Button>
    </Card>
  </Col>
);

CardDomain.propTypes = {
  domainTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default CardDomain;
