import React from 'react';
import PropTypes from 'prop-types';
import {graphql, useStaticQuery} from 'gatsby';

import CardApp from './card-app';

const CardAppModelSystems = props => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "icon_model-systems.png"}) {
        id
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

  return <CardApp icon={data.file.childImageSharp.fixed} {...props} />;
};

CardAppModelSystems.propTypes = {
  icon: PropTypes.object.isRequired,
  tag: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  url: PropTypes.string,
  lg: PropTypes.number,
  sm: PropTypes.number,
};

CardAppModelSystems.defaultProps = {
  tag: 'Xenografts',
  title: 'Model Systems',
  description:
    'Explore patient-derived xenograft data and cell lines generated at St. Jude to enhance basic research and speed translation to the clinic.',
  buttonText: 'Browse Xenografts',
  url: 'https://models.stjude.cloud',
};

export default CardAppModelSystems;
